import Head from 'next/head';
import React, { FC } from 'react';

interface SEOProps {
  title: string;
  description?: string;
}

const SEO: FC<SEOProps> = ({
  title,
  description = 'The best software developers offering outsourcing software development services, advertising & marketing solutions, and website development services.',
}) => {
  const metaImage = `${process.env.BASE_URL}/logo-seo.jpg`;
  const titleTemplate = `${title} | US Software Solutions`;
  return (
    <Head>
      <title>{titleTemplate}</title>
      <meta name="og:title" content={titleTemplate} />
      <meta name="twitter:title" content={titleTemplate} />
      <meta name="description" content={description} />
      <meta name="og:description" content={description} />
      <meta name="twitter:description" content={description} />
      <meta property="image" content={metaImage} />
      <meta property="og:image" content={metaImage} />
      <meta name="twitter:image" content={metaImage} />
      <meta name="twitter:card" content="summary" />
      <meta name="og:type" content="website" />
    </Head>
  );
};

export default SEO;
