import { Box, Container, Grid, GridSize, Hidden, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import { followUsIconLinks } from 'config/routes';
import { ABOUT_LINKS, GENERAL_LINKS, PRODUCTS_LINKS, SERVICE_LINK_LIST } from 'config/routes/linkLists';
import { useMediaUp } from 'hooks';
import Link from 'next/link';
import { FC, Fragment } from 'react';
import { USSLogoIcon } from 'svgs';
import { IFooterIconList, TLinks } from 'templates/layout/types';

const useStyles = makeStyles((theme: Theme) => ({
  listLabel: {
    marginBottom: theme.spacing(4),
  },
  listItem: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  logoLink: {
    marginRight: theme.spacing(3),
    '&:hover': {
      '& path': {
        fill: 'white',
      },
    },
  },
}));

type TFooterCols = { label: string; links: TLinks[]; lgSize: GridSize };

const footerCols: TFooterCols[] = [
  {
    label: 'Services',
    links: SERVICE_LINK_LIST,
    lgSize: 3,
  },
  {
    label: 'General Links',
    links: GENERAL_LINKS,
    lgSize: 2,
  },
  {
    label: 'Products',
    links: PRODUCTS_LINKS,
    lgSize: 2,
  },
];

const Footer: FC = () => {
  const isMobileUp = useMediaUp('xs');
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <Box mb={12} />
      <Container fixed>
        <Box pl={isMobileUp ? 0 : 2}>
          <Grid container>
            <Hidden only="md">
              <Grid lg={3} md={3} xs={12} item>
                <USSLogoIcon />
                <Box mb={10} />
              </Grid>
            </Hidden>
            {footerCols.map(({ lgSize, ...props }, index) => (
              <Grid key={index} lg={lgSize} md={3} sm={6} xs={12} item>
                <LinksList {...props} />
              </Grid>
            ))}
            <Grid lg={2} md={3} sm={6} xs={12} item>
              <LinksIconList label="Follow Us" links={followUsIconLinks} />
              <LinksList label="About" links={ABOUT_LINKS} />
            </Grid>
          </Grid>
          <Box mt={18} mb={5}>
            <Hidden only={['sm', 'xs']} lgUp>
              <Box display="flex" justifyContent="center">
                <USSLogoIcon />
              </Box>
              <Box mb={6} />
            </Hidden>
            <Typography variant="body2" align="center">
              Us Software Solutions {currentYear}. All rights reserved
            </Typography>
          </Box>
        </Box>
      </Container>
    </footer>
  );
};

interface ILinkListLocal {
  label: string;
  links: TLinks[];
}

const LinksList: FC<ILinkListLocal> = ({ label, links }) => {
  const classes = useStyles();
  const isMedium = useMediaUp('sm');
  return (
    <Box mb={isMedium ? 7 : 10}>
      <Typography variant="h4" component="h4" className={classes.listLabel}>
        {label}
      </Typography>
      {links.map(({ label, route }, index) => (
        <Fragment key={index}>
          <Link href={route} passHref>
            <Typography component="a" variant="body2" className={classes.listItem}>
              {label}
            </Typography>
          </Link>
          <Box mb={3} />
        </Fragment>
      ))}
    </Box>
  );
};

const LinksIconList: FC<IFooterIconList> = ({ label, links }) => {
  const classes = useStyles();
  const isMedium = useMediaUp('sm');
  return (
    <Box mb={isMedium ? 7 : 10}>
      <Typography variant="h4" component="h4" className={classes.listLabel}>
        {label}
      </Typography>
      {links.map(({ link, logo }, index) => (
        <a href={link} key={index} target="_blank" rel="noopener noreferrer">
          <IconButton color="primary" className={classes.logoLink}>
            {logo}
          </IconButton>
        </a>
      ))}
    </Box>
  );
};

export default Footer;
