import { SvgIconProps } from '@material-ui/core';
import { FC, forwardRef } from 'react';
import { ReactComponent as LogisticsSvg } from './logistics.svg';
import { ReactComponent as ProfessionalServicesSvg } from './professional-services.svg';
import { ReactComponent as UtilitiesSvg } from './utilities.svg';

export const LogisticsIcon: FC<SvgIconProps> = forwardRef((props, ref) => <LogisticsSvg ref={ref} {...props} />);
export const ProfessionalServicesIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <ProfessionalServicesSvg ref={ref} {...props} />
));
export const UtilitiesIcon: FC<SvgIconProps> = forwardRef((props, ref) => <UtilitiesSvg ref={ref} {...props} />);
