import { Box, Container, Grid, Hidden, makeStyles, Theme, Typography } from '@material-ui/core';
import { ContentDescription } from 'components/structures/general';
import { FC } from 'react';
import { MultiLinesIcon, PlusIcon, RedCirclePointedIcon } from 'svgs';
import { QuoteForm } from 'templates/forms';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: '100%',
    minHeight: 645,
    backgroundColor: theme.palette.primary.dark,
    padding: theme.spacing(17, 0),
    position: 'relative',
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(28),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(19),
      marginBottom: theme.spacing(33),
    },
  },
  square: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: theme.spacing(1),
  },
  title: {
    color: 'white',
    marginBottom: theme.spacing(3),
  },
  upperTitle: {
    '& h4': {
      color: theme.palette.common.white,
    },
  },
  paragraph: {
    marginBottom: theme.spacing(2),
    color: theme.palette.common.white,
  },
  addMargin: { marginTop: theme.spacing(4) },
  multiLinesIcon: {
    position: 'absolute',
    left: theme.spacing(-8),
    top: theme.spacing(6),
    transform: 'rotate(90deg)',
    maxWidth: '100%',
    height: theme.spacing(8),
    width: theme.spacing(42),
    '& path': {
      stroke: theme.palette.primary.light,
    },
  },
  redCircleIcon: {
    position: 'absolute',
    bottom: 0,
    right: theme.spacing(-7),
  },
  plusIcon: {
    position: 'absolute',
    top: theme.spacing(5),
    right: theme.spacing(12),
  },
}));

const RequestQuote: FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.wrapper} id="request-form">
      <Hidden smDown>
        <MultiLinesIcon className={classes.multiLinesIcon} viewBox="0 0 59 11" />
        <RedCirclePointedIcon className={classes.redCircleIcon} />
        <PlusIcon className={classes.plusIcon} />
      </Hidden>
      <Container fixed>
        <Grid container spacing={6}>
          <Grid item md={6} xs={12}>
            <Box maxWidth={510}>
              <ContentDescription
                title={{ text: 'Get in touch', variant: 'h2', className: classes.title }}
                upperTitle={{ text: 'Contact Us', colorSquare: '#55aa88', className: classes.upperTitle }}
              />
              <Typography className={classes.paragraph} variant="body2">
                Do you have questions? We have answers! Fill in the form, and we’ll be happy to get back to you shortly. Need more
                details? We’re always glad to help! Just leave us your email, and our specialists will provide you with all the
                information you need. Simple as that!
              </Typography>
              <Typography className={classes.paragraph} variant="body2">
                Feel free to share your thoughts, ideas, and concepts with us as well! If you feel like you got something – a
                unique proof of concept or an algorithm that’s bound to change the world as we know it, get in touch, and we’ll be
                delighted to help you get started!
              </Typography>
            </Box>
          </Grid>
          <Hidden smDown>
            <Grid item sm={1} />
          </Hidden>
          <Grid item md={5} xs={12}>
            <QuoteForm />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default RequestQuote;
