import { FC } from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';
import { useDarkMode } from 'hooks';
import { DarkModeIcon, LightModeIcon } from 'svgs';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    backgroundColor: (isDarkMode) => (isDarkMode ? theme.palette.primary.light : theme.palette.secondary.light),
    transition: 'background-color 400ms',
    width: theme.spacing(10),
    height: theme.spacing(4),
    borderRadius: theme.spacing(4),
    position: 'relative',
    cursor: 'pointer',
  },
  checkMark: {
    position: 'absolute',
    width: 26,
    height: 26,
    backgroundColor: theme.palette.common.white,
    borderRadius: '50%',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    top: -4,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'transform 400ms ease',
    transform: (isDarkMode) => (isDarkMode ? 'translateX(100%)' : 'translateX(0)'),
  },
}));

const ColorSwitcher: FC = () => {
  const { isDarkMode, handleChangeMode } = useDarkMode();
  const classes = useStyles(isDarkMode);

  const handleToggle = () => handleChangeMode(isDarkMode ? 'light' : 'dark');

  return (
    <Box onClick={handleToggle} className={classes.wrapper}>
      <Box className={classes.checkMark}>{isDarkMode ? <DarkModeIcon /> : <LightModeIcon />}</Box>
    </Box>
  );
};

export default ColorSwitcher;
