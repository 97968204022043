import { Box, Fade, makeStyles, Theme, Typography } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import { LinkButton } from 'components/atoms';
import { navbarLinks } from 'config/routes/routes';
import { useDarkMode } from 'hooks';
import { NextRouter, useRouter } from 'next/dist/client/router';
import Link from 'next/link';
import React, { FC, useState } from 'react';
import { ArrowDownIcon, USSLogoIcon, USSLogoWhite } from 'svgs';
import ColorSwitcher from 'templates/layout/Navbar/ColorSwitcher';
import { IDefaultNavProps, TLinks, TNavLabelLinks } from 'templates/layout/types';

type StyleProps = {
  isDarkMode?: boolean;
  isTransparent?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  activeLink: {
    color: theme.palette.primary.main,
  },
  links: {
    color: theme.palette.switchedColors.frameTitle,
  },
  dropdownLink: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: theme.spacing(1),
      marginTop: 3,
    },
    transition: 'all 300ms',
  },
  isWhiteLink: {
    color: ({ isTransparent }: StyleProps) => isTransparent && theme.palette.common.white,
  },
  lightColor: {
    color: theme.palette.common.white,
  },
  dropDownList: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    zIndex: 1000,
    left: '50%',
    boxShadow: '0px 4px 37px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(8),
    borderRadius: theme.spacing(2),
    margin: 'auto',
    marginTop: theme.spacing(4),
    whiteSpace: 'nowrap',
    transform: 'translate(-50%, 0)',
  },
  linkList: {
    margin: theme.spacing(3, 'auto', 2),
  },
  showMoreIcon: {
    transition: 'all 200ms',
  },
  rotateIcon: {
    transform: 'rotate(-180deg)',
  },
  dropdownLabel: {
    fontSize: 18,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  whiteArrow: {
    '& path': {
      fill: theme.palette.common.white,
    },
  },
  contactLink: {
    background: ({ isDarkMode }: StyleProps) => (isDarkMode ? theme.palette.primary.light : theme.palette.primary.main),
    '&:hover': {
      background: ({ isDarkMode }: StyleProps) => (isDarkMode ? theme.palette.primary.light : theme.palette.primary.main),
    },
  },
}));

const DesktopNavbar: FC<IDefaultNavProps> = ({ transparent, isTop }) => {
  const isTransparent = transparent && isTop;
  const { asPath }: NextRouter = useRouter();
  const { isDarkMode } = useDarkMode();
  const classes = useStyles({ isDarkMode, isTransparent });

  return (
    <Box py={5} display="flex" justifyContent="space-between">
      <Link href="/" passHref>
        <a>{isTransparent ? <USSLogoWhite /> : <USSLogoIcon />}</a>
      </Link>
      <Box display="flex" alignItems="center">
        {navbarLinks.map(({ label, links, route }, index) => (
          <Box mr={6} key={index} className={classes.links}>
            {links ? (
              <NavLinkDropdown
                isTransparent={isTransparent}
                classes={classes}
                label={label}
                links={links}
                active={asPath === route}
              />
            ) : (
              <NavLink classes={classes} label={label} route={route} active={asPath === route} />
            )}
          </Box>
        ))}
        <Box mr={6} ml={4}>
          <ColorSwitcher />
        </Box>
        <LinkButton href="/contact-us" color="primary" variant="contained" className={classes.contactLink}>
          Contact Us
        </LinkButton>
      </Box>
    </Box>
  );
};

interface NavLinkProps extends TLinks {
  active: boolean;
  classes: ClassNameMap<string>;
}

interface INavLinkDropdown {
  label: string;
  links: TNavLabelLinks[];
  active: boolean;
  classes: ClassNameMap<string>;
  isTransparent: boolean;
}

const NavLink: FC<NavLinkProps> = ({ active, label, route, classes }) => {
  const { isDarkMode } = useDarkMode();
  return (
    <Link href={route} passHref>
      <Typography
        variant="body2"
        component="a"
        className={clsx(isDarkMode && !active && classes.lightColor, active && classes.activeLink, classes.isWhiteLink)}
      >
        {label}
      </Typography>
    </Link>
  );
};

const NavLinkDropdown: FC<INavLinkDropdown> = ({ active, label, links, classes, isTransparent }) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const { isDarkMode } = useDarkMode();

  const toggleShowMore = () => setShowMore(true);
  const toggleShowLess = () => setShowMore(false);

  return (
    <Box onMouseEnter={toggleShowMore} onMouseLeave={toggleShowLess} position="relative">
      <Typography
        variant="body2"
        className={clsx(
          classes.dropdownLink,
          isDarkMode && !active && classes.lightColor,
          active && classes.activeLink,
          classes.isWhiteLink,
        )}
        component="a"
      >
        {label}{' '}
        <ArrowDownIcon
          className={clsx(
            classes.showMoreIcon,
            showMore && classes.rotateIcon,
            (isTransparent || isDarkMode) && classes.whiteArrow,
          )}
        />
      </Typography>
      <Fade in={showMore}>
        <Box className={classes.dropDownList}>
          {links.map(({ label, links, icon }, index) => (
            <Box key={index} display="flex">
              <Box mr={2} mt={1}>
                {icon}
              </Box>
              <Box>
                <Typography variant="h4">{label}</Typography>
                <Box className={classes.linkList}>
                  {links.map(({ label, route }) => (
                    <Box mb={2} key={route + label}>
                      <Link href={route} passHref>
                        <Typography component="a" className={clsx(classes.dropdownLabel, active && classes.activeLink)}>
                          {label} <br />
                        </Typography>
                      </Link>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Fade>
    </Box>
  );
};

export default DesktopNavbar;
