import { alpha, AppBar, Container, makeStyles, Theme } from '@material-ui/core';
import { ALL_ROUTES } from 'config/routes/linkLists';
import { useDarkMode } from 'hooks';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';
import { IDefaultNavProps } from 'templates/layout/types';
import DesktopNavbar from './DesktopNavbar';
import MobileNavbar from './MobileNavbar';

interface StyleProps extends IDefaultNavProps {
  isDarkMode: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    backdropFilter: 'blur(12px)',
    color: 'inherit',
    marginTop: -1,
    background: ({ isTop, transparent }: StyleProps) => alpha(theme.palette.background.default, transparent && isTop ? 0 : 0.8),
    borderBottom: ({ transparent, isTop, isDarkMode }) =>
      `1px solid ${alpha(theme.palette.secondary.light, isDarkMode || (transparent && isTop) ? 0 : 0.8)}`,
    height: ({ isTop, transparent }) => isTop && transparent && 0,
  },
  desktopContainer: {
    display: 'none',
    [theme.breakpoints.up(1020)]: {
      display: 'block',
    },
  },
  mobileContainer: {
    display: 'block',
    [theme.breakpoints.up(1020)]: {
      display: 'none',
    },
  },
}));

const Navbar: FC = () => {
  const { asPath } = useRouter();
  const { isDarkMode } = useDarkMode();
  const [isTop, setIsTop] = useState<boolean>(true);
  const [transparent, setTransparent] = useState<boolean>(false);
  const classes = useStyles({ isTop, transparent, isDarkMode });
  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.scrollY > 100 ? setIsTop(false) : setIsTop(true);
    });

    return () => {
      window.removeEventListener('scroll', () => {
        setIsTop(true);
      });
    };
  }, []);

  useEffect(() => {
    setTransparent(false);
    ALL_ROUTES.forEach(({ route, transparentNavbar }) => transparentNavbar && route === asPath && setTransparent(true));
  }, [asPath]);

  return (
    <AppBar className={classes.appBar} position="sticky">
      <Container>
        <div className={classes.desktopContainer}>
          <DesktopNavbar isTop={isTop} transparent={transparent} />
        </div>
        <div className={classes.mobileContainer}>
          <MobileNavbar isTop={isTop} transparent={transparent} />
        </div>
      </Container>
    </AppBar>
  );
};

export default Navbar;
