import { FC, useEffect, useState } from 'react';
import { Box, Collapse, Divider, Drawer, IconButton, makeStyles, Theme, Typography, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import { navbarLinks } from 'config/routes/routes';
import { useDarkMode } from 'hooks';
import { NextRouter, useRouter } from 'next/dist/client/router';
import Link from 'next/link';
import { ArrowDownIcon, USSLogoIcon } from 'svgs';
import ColorSwitcher from 'templates/layout/Navbar/ColorSwitcher';
import { IDefaultNavProps, TLinks, TNavLabelLinks } from 'templates/layout/types';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

interface StyleProps extends IDefaultNavProps {
  isDarkMode: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  burgerMenu: {
    width: 18,
    cursor: 'pointer',
  },
  burgerLine: {
    height: 2,
    backgroundColor: ({ isDarkMode, isTop, transparent }: StyleProps) =>
      isDarkMode || (isTop && transparent) ? theme.palette.common.white : theme.palette.common.black,
    transition: 'background-color 400ms',
    marginBottom: theme.spacing(1),
  },
  activeLink: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(2),
  },
  showMoreIcon: {
    transition: 'all 200ms',
  },
  rotateIcon: {
    transform: 'rotate(-180deg)',
  },
  whiteArrow: {
    '& path': {
      fill: theme.palette.common.white,
    },
  },
  dropDownList: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(2, 0),
  },
  dropdownLink: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: theme.spacing(1),
      marginTop: 3,
    },
    transition: 'all 300ms',
  },
  lastListItem: {
    margin: 0,
  },
  dropdownLabel: {
    marginBottom: theme.spacing(3),
    color: theme.palette.text.primary,
  },
  listLabel: {
    fontSize: 20,
    marginBottom: theme.spacing(4),
  },
}));

const StyledDrawer = withStyles((theme: Theme) => ({
  paper: {
    width: '100%',
    maxWidth: theme.spacing(60),
    padding: theme.spacing(7, 3),
  },
}))(Drawer);

const MobileNavbar: FC<IDefaultNavProps> = ({ isTop, transparent }) => {
  const [open, setOpen] = useState(false);
  const { asPath }: NextRouter = useRouter();
  const { isDarkMode } = useDarkMode();
  const classes = useStyles({ isTop, isDarkMode, transparent });
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  useEffect(handleClose, [asPath]);

  return (
    <>
      <Box py={4} display="flex" justifyContent="space-between">
        <Link href="/">
          <a>
            <USSLogoIcon />
          </a>
        </Link>
        <IconButton onClick={handleOpen}>
          <BurgerMenu classes={classes} />
        </IconButton>
      </Box>
      <StyledDrawer anchor="left" open={open} onClose={handleClose}>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={10}>
          <Link href="/" passHref>
            <a>
              <USSLogoIcon />
            </a>
          </Link>
          <ColorSwitcher />
        </Box>
        {navbarLinks.map(({ label, links, route }, index) => (
          <Box mr={6} key={index}>
            {links ? (
              <NavLinkDropdown classes={classes} label={label} links={links} active={asPath === route} />
            ) : (
              <NavLink classes={classes} label={label} route={route} active={asPath === route} />
            )}
          </Box>
        ))}
        <Box mr={6}>
          <NavLink classes={classes} label={'Contact Us'} route={'/contact-us'} active={asPath === 'contact-us'} />
        </Box>
      </StyledDrawer>
    </>
  );
};

interface NavLinkProps extends TLinks {
  active: boolean;
  classes: ClassNameMap<string>;
}

interface NavLinkDropdown {
  label: string;
  links: TNavLabelLinks[];
  active: boolean;
  classes: ClassNameMap<string>;
}

const NavLink: FC<NavLinkProps> = ({ active, label, route, classes }) => (
  <Box mb={6}>
    <Link href={route} passHref>
      <Typography variant="body2" component="a" className={clsx(active && classes.activeLink)}>
        {label}
      </Typography>
    </Link>
  </Box>
);

const NavLinkDropdown: FC<NavLinkDropdown> = ({ active, label, links, classes }) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const { isDarkMode } = useDarkMode();

  const toggleMenu = () => setShowMore((prevState) => !prevState);

  return (
    <Box onClick={toggleMenu} position="relative" mb={6}>
      <Typography variant="body2" className={classes.dropdownLink}>
        {label}{' '}
        <ArrowDownIcon className={clsx(classes.showMoreIcon, showMore && classes.rotateIcon, isDarkMode && classes.whiteArrow)} />
      </Typography>
      <Collapse in={showMore}>
        <Box className={classes.dropDownList}>
          <Divider />
          <Box mb={3} />
          {links.map(({ label, icon, links }, index, origin) => (
            <Box key={label + index} display="flex">
              <Box mt={1} mr={2}>
                {icon}
              </Box>
              <Box>
                <Typography variant="h5" className={classes.listLabel}>
                  {label}
                </Typography>
                {links.map(({ label, route }, index) => (
                  <Box key={label + index} mb={3}>
                    <Link href={route} passHref>
                      <Typography
                        component="a"
                        variant="subtitle2"
                        className={clsx(
                          classes.dropdownLabel,
                          active && classes.activeLink,
                          index + 1 === origin.length && classes.lastListItem,
                        )}
                      >
                        {label}
                      </Typography>
                    </Link>
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
          <Box mb={3} />
          <Divider />
        </Box>
      </Collapse>
    </Box>
  );
};

const BurgerMenu: FC<{ classes: ClassNameMap<string> }> = ({ classes: { burgerLine, burgerMenu } }) => {
  return (
    <Box className={burgerMenu}>
      <Box className={burgerLine} />
      <Box className={burgerLine} />
      <Box style={{ marginBottom: 0 }} className={burgerLine} />
    </Box>
  );
};

export default MobileNavbar;
